<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical" class="sign-form" :rules="rules">
        <div class="header-block">
          <sdHeading as="h3" class="text-center">
            {{  $t('auth.want_new_password') }}
            <p class="small-text">{{$t('auth.new_password_indications')}}</p>
          </sdHeading>
        </div>
        <!-- <p class="forgot-text">
          {{  $t('auth.notice') }}
        </p> -->
        <div class="input-block">
          <a-form-item :label="$t('auth.new_password')" name="password">
            <a-input type="password" v-model:value="formState.password" />
          </a-form-item>
          <a-form-item :label="$t('auth.repeat_new_password')" name="passwordConfirm">
            <a-input type="password" v-model:value="formState.passwordConfirm"/>
          </a-form-item>
          <a-form-item :label="$t('auth.email')" name="email">
            <a-input type="email" v-model:value="formState.email" />
          </a-form-item>
        </div>
        <div class="button-block">
          <a-form-item>
 
            <sdButton class="btn-reset element-center" htmlType="submit" type="primary" size="large" :loading="isLoading"> {{ $t('auth.change_password_action_button') }} </sdButton>
          </a-form-item>
        </div>
        <p class="return-text">{{  $t('auth.return_to') }} <router-link to="/auth/login">{{  $t('auth.sign_in') }}</router-link></p>
      </a-form>
      <a-modal v-model:visible="errorModal" title="Error" @ok="closeModal">
        {{$t('auth.forgot_password_error')}}
      </a-modal>
    </div>
  </AuthWrapper>
</template>
<script setup>
import { reactive, ref, computed } from 'vue';
import { AuthWrapper } from './style';
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import { successToast } from '../../utility/toast';
import { useI18n } from "vue-i18n";

const { t } = useI18n();


const authStore = useAuthStore();

const isLoading = computed(() => authStore.loading)
const router = useRouter();

const errorModal = ref(false)
const showErrorModal = () => errorModal.value = true

const closeModal = () => {
  errorModal.value = false
}

let confirmPassword = async (rule, value) => {
      if (value === '') {
        return Promise.reject(t('auth.rules.repeat_new_password'));
      } else if (value !== formState.password) {
        return Promise.reject(t('auth.rules.passwords_dont_match'));
      } else {
        return Promise.resolve();
      }
    };

const rules = {
  password: [{ required: true, message: t('auth.rules.new_password'), trigger: 'change' }],
  passwordConfirm: [{ required: true, validator: confirmPassword, trigger: 'change' }],
  email: [{ required: true, message: t('auth.rules.email'), trigger: 'change' }],
};


const formState = reactive({
  password: '',
  passwordConfirm: '',
  email: ''
});

let token = window.location.href.split('=')[1];

const handleSubmit = () => {
  let newPasswordData = {...formState, token}
  authStore.resetPassword(newPasswordData)
  .then(() => {
    successToast(t('auth.forgot_password_success_toast_title'),t('auth.forgot_password_success_toast_message'));
    router.push({ name: 'login' }).catch((err) => console.info("route error: ", err));
  })
  .catch(() => showErrorModal())
};


</script>
<style scoped lang="scss">

.auth-contents {
  height: 100%;
}
.sign-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

// FORM HEADER
.lavNKW .auth-contents form h1 {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media only screen and (max-width: 991px) {
  .duPWIV {
    padding: 85px !important;
  }
  .auth-contents {
    height: 72vh;
  }
}
</style>
